@import 'reset';
@import 'fonts';

body {
  font-family: 'Inter-UI', -apple-system, BlinkMacSystemFont, sans-serif !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-weight: 400;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'pnum';
  font-feature-settings: 'pnum';
  font-variant-numeric: proportional-nums;
  width: 100%;
  max-width: 100% !important;
  overflow-x: hidden !important;
  height: 100%;
  min-height: 100%;
  touch-action: manipulation;

  @media screen and (max-width: 1040px) {
    overflow-scrolling: touch;
    user-select: none;
  }
}
img {
  image-rendering: optimizeSpeed; /*                     */
  image-rendering: -moz-crisp-edges; /* Firefox             */
  image-rendering: -o-crisp-edges; /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: optimize-contrast; /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                */
}

@import 'chakraModal';
@import 'lightbox';
@import 'layouts';
@import 'dashboard';
@import 'infoWrapper';
@import 'draggable';
