@font-face {
  font-family: Inter-UI;
  src: url(https://d33wanxuedozem.cloudfront.net/fonts/Inter-UI-Regular.woff2)
      format('woff2'),
    url(https://d33wanxuedozem.cloudfront.net/fonts/Inter-UI-Regular.woff)
      format('woff'),
    url(https://d33wanxuedozem.cloudfront.net/fonts/Inter-UI-Regular.ttf)
      format('truetype');
  font-weight: 400;
  font-style: normal;
}
