@import '../components/variables.scss';

/*
 * React image lighbox
 * ========================================================================== */
.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 10000 !important;
  }

  .ril-outer {
    background: rgba(0, 0, 0, 0.24);

    &.animate {
      .ril-inner {
        :global {
          animation: fadeIn 0.2s;
          animation-fill-mode: forwards;
        }
      }
    }

    &.ril-closing {
      .ril-inner {
        :global {
          animation: fadeOut 0.2s;
          animation-fill-mode: forwards;
        }
      }
    }

    .ril-inner {
      cursor: zoom-out;

      img {
        image-orientation: from-image;
        pointer-events: none;
      }
    }
  }

  .ril-loading-circle {
    display: none !important;
  }

  .ril-toolbar {
    display: none !important;
    pointer-events: none !important;
  }

  .ril-caption {
    background: rgba(0, 0, 0, 0.8);
    bottom: 16px;
    width: 296px;
    margin: auto;
    border-radius: 6px;
    z-index: 9999;
    bottom: 16px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);

    .ril-caption-content {
      margin: 0 auto;
      padding: 0;
    }
  }

  .ril-prev-button,
  .ril-next-button {
    background: #000;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 30px;
    box-shadow: 0 1px 4px 0 rgba(45, 59, 79, 0.2);
    height: 30px;
    opacity: 1;
    outline: none;
    padding: 0;
    width: 30px;

    &:hover {
      background-color: #666666;
    }
  }

  .ril-prev-button {
    background-image: url('../icons/prev.svg');
    left: 16px;
    right: auto;
  }

  .ril-next-button {
    background-image: url('../icons/next.svg');
    left: auto;
    right: 16px;
  }
}
