i.info {
  background: #eeeeee;
}
i.info-search {
  background: #ffffff !important;
  border: 1.5px solid #000000;
  font-weight: bold;
  line-height: 13px !important;
}

//Mentions box
.comment-box__suggestions {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 6px;
}
