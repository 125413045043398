@import '../components/variables.scss';

.dashboardContentContainer {
  position: relative;
  background: #ffffff;
  width: calc(100% - 300px);
  max-width: 940px;
  margin: 40px auto;
  transition: all 0.25s cubic-bezier(0.17, 0.67, 0.83, 1);

  &.contentSlided {
    left: 0;
  }

  @media only screen and (max-width: 1040px) {
    left: 0;
    right: 0;
    margin: 100px auto;
    max-width: calc(480px - 15px);
    padding: 10px;
    padding-bottom: 40px;
    width: 100%;
  }
}
