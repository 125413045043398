@media only screen and (max-width: 1040px) {
  .chakra-modal__content {
    max-width: calc(100% - 16px) !important;
    margin: 16px auto !important;
    margin-bottom: 40px !important;
    overflow-x: hidden;

    .chakra-modal__body {
      padding: 24px 16px;
    }
  }
}
