.centerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridPlatform {
  position: relative;
  background: #ffffff;
  min-height: 100vh;
  overflow-x: hidden;

  .side {
    width: 100%;
    grid-area: sidebar;
    z-index: 10;
    @media only screen and (max-width: 1040px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
      max-width: 480px;
      margin: auto;
      background: linear-gradient(
        180deg,
        #ffffff 73.54%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .main-head {
    grid-area: header;
    height:56px;
  }

  .content {
    grid-area: main;
  }
}

.container {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;

  @media only screen and (max-width: 1040px) {
    max-width: 720px;
  }
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

.contentContainer {
  position: relative;
  width: 100%;
}

.panelContainer {
  position: relative;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px 16px 0 16px;
}

//Chevron
.chevron {
  &:hover {
    i {
      background-image: url('../icons/chevron-down-active.svg');
    }
  }

  &.chevronExpanded {
    i {
      background-image: url('../icons/chevron-up-active.svg');
    }
  }

  &.chevronDark {
    i {
      background-image: url('../icons/chevron-down-dark-inactive.svg');

      &:hover {
        background-image: url('../icons/chevron-down-dark-active.svg');
      }
    }

    &:hover {
      i {
        background-image: url('../icons/chevron-down-dark-active.svg');
      }
    }

    &.chevronExpanded {
      i {
        background-image: url('../icons/chevron-up-dark-active.svg');
      }
    }
  }

  i {
    background-image: url('../icons/chevron-down-inactive.svg');
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    vertical-align: middle;
    width: 16px;

    &:hover {
      background-image: url('../icons/chevron-down-active.svg');
    }
  }
}

.chevron-left {
  background-image: url('../icons/chevron-left-inactive.svg');
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  vertical-align: middle;
  width: 16px;

  &:hover {
    background-image: url('../icons/chevron-left-active.svg');
  }
}

.chevron-right {
  background-image: url('../icons/chevron-right-inactive.svg');
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  vertical-align: middle;
  width: 16px;

  &:hover {
    background-image: url('../icons/chevron-right-active.svg');
  }
}
//End chevron

//Lightbox modal
.ReactModal__Overlay {
  z-index: 99999 !important;
}
//End lightbox modal
